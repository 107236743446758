/* eslint-disable camelcase */
import { ResFilterList } from '@/apis/filter/type';
import {
  ResPatientDevicePairList,
  ReqTrendData,
  ResRoxTrendData,
  ReqDeviceConnectionList,
  ResTrendData,
  ResTotalTrendData,
  ResDeviceConnectionList,
  ReqAllParamsTrend,
  ResNibpTrend,
  ResWaveDetail,
  WaveData,
  ResLastDeviceInfo,
} from '@/apis/patient/type';
import { TTrend } from '@/constants/types';
import { TConnectionInfo } from '@/contexts/patientInfoStore/type';
import { devices } from '@/mock/data';
import {
  HFTTrendData,
  HFT_TOTAL_TREND_PARAMETERS,
  MPTrendData,
  MP_TOTAL_TREND_PARAMETERS,
  MV2000TrendData,
  MV2000_TOTAL_TREND_PARAMETERS,
  MV50TrendData,
  MV50_TOTAL_TREND_PARAMETERS,
  ResTotalTrendDataV1,
  ResWaveOverview,
  TTrendData,
  TrendValue,
  WaveOverview,
} from '@/pages/CentralMain/organisms/TrendModal/constants';
import { getFormattedTimeValue } from '@/pages/CentralMain/organisms/TrendModalTemp/organisms/SciTrend/util/createXYDataSeries';
import { Data, DeviceModel, PARAM_KEYS, TParamKey } from '@/pages/CentralMain/types';
import { SeededRandom } from '@/utils/random';

import { makeWaves } from '../data/wave/generateWaveSimulation';
import { ECGWaveData, HFTTrendDatas, MPTrendDatas, MV2000TrendDatas, MV50TrendDatas } from '../trend/allTrendData';
import { trendData } from '../trend/trendData';

const postRoxTrend = (req: Required<ReqTrendData>) => {
  const fromDate = new Date(`${req.from}`);
  const toDate = new Date(`${req.to}`);

  const data: { dateTime: string; roxValue: number }[] = [];

  // 분 단위로 나눠서 그 분의 trend data 를 찾는다.
  let currentTimeIndex = fromDate.getTime() / (60 * 1000);
  const toTimeIndex = toDate.getTime() / (60 * 1000);
  while (currentTimeIndex <= toTimeIndex) {
    const targetData = trendData.rox;
    if (targetData) {
      const value = targetData[currentTimeIndex % targetData.length];
      data.push({
        dateTime: new Date(currentTimeIndex * 60 * 1000).toISOString(),
        roxValue: value,
      });
    }
    currentTimeIndex += 1;
  }

  return new Promise<ResRoxTrendData>((resolve) =>
    resolve({
      deviceId: req.deviceSerial,
      totalCount: '318',
      resCode: 'SUCCESS',
      resMsg: 'Successfully gathered ROX data',
      downSampleInterval: 0,
      data,
    }),
  );
};

const postTrend = (req: Required<ReqTrendData>, param: TTrend) => {
  const fromDate = new Date(`${req.from}`);
  const toDate = new Date(`${req.to}`);

  const data: { dateTime: string; value: number }[] = [];

  // 분 단위로 나눠서 그 분의 trend data 를 찾는다.
  let currentTimeIndex = fromDate.getTime() / (60 * 1000);
  const toTimeIndex = toDate.getTime() / (60 * 1000);
  while (currentTimeIndex <= toTimeIndex) {
    const targetData = trendData[param];
    if (targetData) {
      const value = targetData[currentTimeIndex % targetData.length];
      data.push({
        dateTime: new Date(currentTimeIndex * 60 * 1000).toISOString(),
        value,
      });
    }
    currentTimeIndex += 1;
  }

  return new Promise<ResTrendData>((resolve) =>
    resolve({
      deviceSerial: req.deviceSerial,
      totalCount: '318',
      resCode: 'SUCCESS',
      resMsg: 'Successfully gathered ROX data',
      data,
    }),
  );
};

const postPatCurrListHandler = (
  req: ReqDeviceConnectionList,
  admitInfo: TConnectionInfo,
  alias: { [key: string]: string },
) => {
  const request = req as unknown as ReqDeviceConnectionList;
  const regexp = new RegExp(request.filter);
  const basePatList = devices.map((device) => device.getPatData(admitInfo, alias));
  let result: ResDeviceConnectionList['data'] = basePatList.filter((val) => (val.bed_id?.search(regexp) || 0) >= 0);
  result = result.filter(
    (val) =>
      (val.bed_id?.search(new RegExp(`${request.bedId.slice(4)}`, 'i')) || 0) >= 0 &&
      (val.patient_id?.search(new RegExp(`${request.patId.slice(4)}`, 'i')) || 0) >= 0 &&
      val.device_serial.search(new RegExp(`${request.deviceId.slice(4)}`, 'i')) >= 0,
  );
  const parts: string[] = request.fullName.slice(4).split(' ');
  const firstNameReg = new RegExp(`${parts[0]}`, 'i');
  const lastNameReg = new RegExp(`${parts[1]}`, 'i');
  result = result.filter(
    (val) =>
      firstNameReg.test(`${val.patient_first_name}`) ||
      firstNameReg.test(`${val.patient_last_name}`) ||
      lastNameReg.test(`${val.patient_last_name}`) ||
      lastNameReg.test(`${val.patient_last_name}`),
  );
  return new Promise<ResDeviceConnectionList>((resolve) =>
    resolve({
      resCode: 'SUCCESS',
      resMsg: 'Successfully gathered current monitoring data',
      totalCount: '0',
      data: result,
    }),
  );
};

const getPdpCurrList = () => {
  const basePdpList = devices.map((device) => device.getPdpData());
  const result: ResPatientDevicePairList = {
    data: basePdpList as unknown as Data[],
  };
  return result;
};
const postLogin = (id: string) => {
  return {
    access_token:
      'eyJhbGciOiJIUzI1NiJ9.eyJjcmVhdGVkQXQiOjE2OTEzNzkzODU3MDksInJvbGUiOjEsImlkIjoiZ2VuZXNpcyIsImVtYWlsIjoiYmlvbkBtZWstaWNzLmNvbSIsImV4cCI6MTY5MTM4MDU4NX0.a8-WJTQALu1MO2HMgotLAKXtFGxvNBfyE7CrWpuU0CA',
    refresh_token:
      'eyJhbGciOiJIUzI1NiJ9.eyJjcmVhdGVkQXQiOjE2OTEzNzkzODU3MDksInJvbGUiOjAsImlkIjoiZ2VuZXNpcyIsImVtYWlsIjoiYmlvbkBtZWstaWNzLmNvbSIsImV4cCI6MTY5MTQ2NTc4NX0.mHOrFmmOrs4BByTWWz8AWiN1vVk8Xg1p3D-i6iJg0WM',
    resUserLoginRetVO: {
      resCode: 'SUCCESS',
      resMsg: '',
      eat: 'b52b806c-9db0-4604-8565-a8f7ef349e95',
      id,
      fullName: 'SystemAdmin',
      lastName: 'System',
      firstName: 'Admin',
      password: '',
      email: 'bion@mek-ics.com',
      regDate: 1679643058000,
      userCateCode: 'ADMIN',
      activeYn: 'Y',
      fingerprint: 'fingerprint',
      multiLoginUser: true,
      resEatVO: {
        resCode: 'SUCCESS',
        resMsg: '',
        eat: 'b52b806c-9db0-4604-8565-a8f7ef349e95',
        name: 'MEK',
        phoneNumber: '070-7119-2500',
        typeCode: 'SYSTEM',
        region: 'AF',
        country: null,
        city: null,
        timezone: null,
        zip: '039490',
        address: '서울시 마포구 성산동 133-7',
        email: 'bion@mek-ics.com',
        regDate: 1678844480000,
        regUserId: id,
        mgrTel: null,
        mgrName: null,
        mgrEmail: null,
        activeYn: 'Y',
        logo: null,
      },
      resourceNames: [
        { resourceName: 'All', resourceDesc: '' },
        { resourceName: '6F', resourceDesc: '' },
        { resourceName: 'Central Patient Info Edit Button', resourceDesc: '' },
        { resourceName: 'Patient Modification Button', resourceDesc: '' },
        { resourceName: 'Patient Registration Button', resourceDesc: '' },
      ],
      menuNames: [],
    },
    userSettingInfoVO: {
      id,
      rowsPerPage: '10',
      isSeparateNameField: 'false',
    },
  };
};
const getFilterList = () => {
  const filterList = [
    { filterName: 'All', regex: '.*', regDate: 1234 },
    { filterName: '6F', regex: '.*6F.*', regDate: 1234 },
    // { filterName: 'All', regex: '.*', regDate: 1234 },
  ];

  return new Promise<ResFilterList>((resolve) =>
    resolve({
      resCode: 'SUCCESS',
      resMsg: '',
      pageNumber: 0,
      rowsPerPage: 0,
      list: filterList,
    }),
  );
};

const postAllParamTrendV1 = (req: Required<ReqAllParamsTrend>) => {
  const fromDate = new Date(`${req.start}`);
  const toDate = new Date(`${req.end}`);

  const interval = 60000; // 1분 간격
  let currentTime = fromDate;
  let index = getFormattedTimeValue(req.start) / 60;
  // eslint-disable-next-line camelcase
  const HFTData: (HFTTrendData & { date_time: string })[] = [];
  // eslint-disable-next-line camelcase
  const MPData: (MPTrendData & { date_time: string })[] = [];
  // eslint-disable-next-line camelcase
  const MV50Data: (MV50TrendData & { date_time: string })[] = [];
  // eslint-disable-next-line camelcase
  const MV2000Data: (MV2000TrendData & { date_time: string })[] = [];
  while (currentTime < toDate) {
    const HFTObj: HFTTrendData = {
      rox: null,
      sf_ratio: null,
      rr: null,
      fio2: null,
      flow: null,
      pi: null,
      peep: null,
      spo2: null,
      pr: null,
      estimated_tidal_volume: null,
      pip: null,
      v_delivery: null,
    };
    Object.keys(HFT_TOTAL_TREND_PARAMETERS).forEach(
      // eslint-disable-next-line no-loop-func, no-return-assign
      (key) => {
        HFTObj[key as keyof HFTTrendData] =
          HFTTrendDatas[key as keyof HFTTrendData][index % HFTTrendDatas[key as keyof HFTTrendData].length];
      },
    );
    HFTData.push({
      date_time: currentTime.toISOString(),
      ...HFTObj,
    });
    const MPObj: MPTrendData = {
      hr: null,
      spo2: null,
      tmp1: null,
      tmp2: null,
      rr: null,
      ibp1_s: null,
      ibp1_d: null,
      ibp1_m: null,
      ibp2_s: null,
      ibp2_d: null,
      ibp2_m: null,
      nibp_s: null,
      nibp_d: null,
      nibp_m: null,
      etco2: null,
      fico2: null,
    };
    Object.keys(MP_TOTAL_TREND_PARAMETERS).forEach(
      // eslint-disable-next-line no-loop-func, no-return-assign
      (key) => {
        MPObj[key as keyof MPTrendData] =
          MPTrendDatas[key as keyof MPTrendData][index % MPTrendDatas[key as keyof MPTrendData].length];
      },
    );
    MPData.push({
      date_time: currentTime.toISOString(),
      ...MPObj,
    });
    const MV50Obj: MV50TrendData = {
      spo2: null,
      rr: null,
      pr: null,
      pi: null,
      peep: null,
      flow: null,
      fio2: null,
      ppeak: null,
      ppause: null,
      pmean: null,
      rr_spont: null,
      etco2_rr: null,
      vti: null,
      vte: null,
      vme: null,
      ti: null,
      te: null,
      tc: null,
      fi_peak: null,
      fe_peak: null,
      peep_h: null,
      peep_l: null,
      vico2: null,
      veco2: null,
      vmeco2: null,
      rsbi: null,
      wobv: null,
      cl: null,
      ra: null,
    };
    Object.keys(MV50_TOTAL_TREND_PARAMETERS).forEach(
      // eslint-disable-next-line no-loop-func, no-return-assign
      (key) => {
        MV50Obj[key as keyof MV50TrendData] =
          MV50TrendDatas[key as keyof MV50TrendData][index % MV50TrendDatas[key as keyof MV50TrendData].length];
      },
    );
    MV50Data.push({
      date_time: currentTime.toISOString(),
      ...MV50Obj,
    });
    const MV2000Obj: MV2000TrendData = {
      rr: null,
      peep: null,
      ppeak: null,
      pmean: null,
      vte: null,
      vme: null,
    };
    Object.keys(MV2000_TOTAL_TREND_PARAMETERS).forEach(
      // eslint-disable-next-line no-loop-func, no-return-assign
      (key) => {
        MV2000Obj[key as keyof MV2000TrendData] =
          MV2000TrendDatas[key as keyof MV2000TrendData][index % MV2000TrendDatas[key as keyof MV2000TrendData].length];
      },
    );
    MV2000Data.push({
      date_time: currentTime.toISOString(),
      ...MV2000Obj,
    });
    currentTime = new Date(currentTime.getTime() + interval);
    index += 1;
  }

  return new Promise<ResTotalTrendDataV1>((resolve) =>
    resolve({
      from: req.start,
      to: req.end,
      manufacturerCode: req.manufacturerCode,
      deviceModel: req.model as DeviceModel,
      deviceSerial: req.deviceSerial,
      mpData: MPData,
      mv2000Data: MV2000Data,
      mv50Data: MV50Data,
      hftData: HFTData,
    }),
  );
};

const postAllParamTrendV2 = (req: Required<ReqAllParamsTrend>) => {
  const fromDate = new Date(`${req.start}`);
  const toDate = new Date(`${req.end}`);

  let index = fromDate.getTime() / (60 * 1000);
  const toIndex = toDate.getTime() / (60 * 1000);
  // eslint-disable-next-line camelcase
  const data: (TTrendData & { date_time: string })[] = [];
  while (index <= toIndex) {
    const obj: TTrendData = {};
    PARAM_KEYS.forEach(
      // eslint-disable-next-line no-loop-func, no-return-assign
      (key) => {
        const targetData = trendData[key];
        if (targetData) {
          obj[key as TParamKey] = targetData[index % 70];
        }
      },
    );
    data.push({
      date_time: new Date(index * 60 * 1000).toISOString(),
      ...obj,
    });
    index += 1;
  }

  return new Promise<ResTotalTrendData>((resolve) =>
    resolve({
      from: req.start,
      to: req.end,
      manufacturerCode: req.manufacturerCode,
      deviceModel: req.model as DeviceModel,
      deviceSerial: req.deviceSerial,
      data,
    }),
  );
};

const getMockNibpTrend = (req: Required<ReqAllParamsTrend>) => {
  const fromDate = new Date(`${req.start}`);
  const toDate = new Date(`${req.end}`);

  let index = fromDate.getTime() / (60 * 1000);
  const toIndex = toDate.getTime() / (60 * 1000);
  // eslint-disable-next-line camelcase
  const data: {
    date_time: string;
    nibp_s: TrendValue;
    nibp_d: TrendValue;
    nibp_m: TrendValue;
  }[] = [];
  while (index <= toIndex) {
    const rest = index % 70;
    if (rest === 1 || rest === 15 || rest === 30 || rest === 45 || rest === 60) {
      const obj: ResNibpTrend['data'][0] = {
        date_time: '',
        nibp_s: undefined,
        nibp_d: undefined,
        nibp_m: undefined,
      };
      const rng = new SeededRandom(index);
      obj.nibp_s = (trendData.ibp1_s?.[rest] || 0) + rng.nextInRange(-1, 1);
      obj.nibp_m = (trendData.ibp1_m?.[rest] || 0) + rng.nextInRange(-1, 1);
      obj.nibp_d = (trendData.ibp1_d?.[rest] || 0) + rng.nextInRange(-1, 1);

      data.push({
        ...obj,
        date_time: new Date(index * 60 * 1000).toISOString(),
      });
    }
    index += 1;
  }

  return new Promise<ResNibpTrend>((resolve) =>
    resolve({
      resCode: '',
      resMsg: '',
      totalCount: 0,
      pageNumber: 0,
      rowsPerPage: 0,
      totalPage: 0,
      data,
    }),
  );
};

const getMockWaveOverview = (req: Required<ReqAllParamsTrend>) => {
  const fromDate = new Date(`${req.start}`);
  const toDate = new Date(`${req.end}`);

  const interval = 60000; // 1분 간격
  let currentTime = fromDate;
  // eslint-disable-next-line camelcase
  const data: ({
    date_time: string;
  } & WaveOverview)[] = [];

  let index = getFormattedTimeValue(req.start);
  while (currentTime <= toDate) {
    const obj: WaveOverview = {
      ecg_wave: { ...ECGWaveData, ecg_wave_ii_hz: '240' },
      ibp_wave: {
        ibp1_wave: makeWaves({ device: 'MP1300', startIndex: index }).ibp1_wave.map((item) => item - 900),
        ibp2_wave: makeWaves({ device: 'MP1300', startIndex: index }).ibp2_wave.map((item) => item - 900),
        ibp1_wave_hz: '150',
        ibp2_wave_hz: '150',
      },
      resp_wave: makeWaves({ device: 'MP1300', startIndex: index }).resp_wave.map((item) => item + 500),
      resp_wave_hz: '120',
      spo2_wave: makeWaves({ device: 'MP1300', startIndex: index }).spo2_wave.map((item) => item * 0.5 + 500),
      spo2_wave_hz: '150',
    };
    data.push({
      ...obj,
      date_time: currentTime.toISOString(),
    });
    index += 1;
    currentTime = new Date(currentTime.getTime() + interval);
  }

  return new Promise<ResWaveOverview>((resolve) =>
    resolve({
      from: req.start,
      to: req.end,
      manufacturerCode: req.manufacturerCode,
      deviceModel: req.model as DeviceModel,
      deviceSerial: req.deviceSerial,
      data,
    }),
  );
};

const getMockWaveDetail = (req: Required<ReqAllParamsTrend>) => {
  const fromDate = new Date(`${req.start}`);
  const toDate = new Date(`${req.end}`);

  const interval = 1000; // 1초 간격
  let currentTime = fromDate;
  // eslint-disable-next-line camelcase
  const data: ({
    date_time: string;
  } & WaveData)[] = [];

  let index = getFormattedTimeValue(req.start) / 1000;

  while (currentTime <= toDate) {
    const wave = makeWaves({ device: 'MP1300', startIndex: index });
    const obj: WaveData = {
      ecg_wave: { ...ECGWaveData, ecg_wave_ii_hz: '240', ecg_wave_ii_resolution: '240' },
      ibp_wave: {
        ibp1_wave: wave.ibp1_wave.map((item) => item - 900),
        ibp2_wave: wave.ibp2_wave.map((item) => item - 900),
        ibp1_wave_hz: '150',
        ibp2_wave_hz: '150',
      },
      resp_wave: wave.resp_wave.map((item) => item + 500),
      resp_wave_hz: '120',
      spo2_wave: wave.spo2_wave.map((item) => item * 0.5 + 500),
      spo2_wave_hz: '150',
    };
    data.push({
      ...obj,
      date_time: currentTime.toISOString(),
    });
    index += 1;
    currentTime = new Date(currentTime.getTime() + interval);
  }

  return new Promise<ResWaveDetail>((resolve) =>
    resolve({
      from: req.start,
      to: req.end,
      manufacturerCode: req.manufacturerCode,
      deviceModel: req.model as DeviceModel,
      deviceSerial: req.deviceSerial,
      data,
    }),
  );
};

const getMockLastDeviceInfo = (req: Omit<ReqAllParamsTrend, 'sortOrder'>) => {
  const targetPdp = devices
    .map((device) => device.getPdpData())
    .find(
      (value) =>
        value.man_code === req.manufacturerCode &&
        value.device_model === req.model &&
        value.device_serial === req.deviceSerial,
    );
  const result: ResLastDeviceInfo = {
    man_code: req.manufacturerCode,
    device_serial: req.deviceSerial,
    model: req.model,
    bed_id: targetPdp?.bed_id || '',
  };
  return result;
};

export {
  postPatCurrListHandler,
  getPdpCurrList,
  postLogin,
  postRoxTrend,
  postTrend,
  getFilterList,
  postAllParamTrendV1,
  postAllParamTrendV2,
  getMockNibpTrend,
  getMockWaveOverview,
  getMockWaveDetail,
  getMockLastDeviceInfo,
};

const env: typeof window._env_ =
  process.env.NODE_ENV === 'development'
    ? {
        REACT_APP_HOST: 'https://home.mekcare.com/api',
        // REACT_APP_HOST: 'http://127.0.0.1:8080/api',
        REACT_APP_MQTT_PUSHER_HOST: 'https://home.mekcare.com/api',
        REACT_APP_MQTT_BROKER_URL: 'wss://home.mekcare.com:9001',
        REACT_APP_MQTT_TOPIC: 'AWS-Anywhere',
        REACT_APP_NO_LOG: 'false',
        REACT_APP_MQTT_CACHE_TIME: '10000',
        // DEMO 모드로 살행하는 경우 세팅
        REACT_APP_MODE: 'demo',
        // 119 모드로 살행하는 경우 세팅
        REACT_APP_119: 'true',
        REACT_APP_DEMO_TARGET_HOSPITAL: 'omniox',
        REACT_APP_IMAGE_TAG: '-- (Dev Mode)',
        // 다른 개발용 runtime 환경 변수들...
      }
    : // eslint-disable-next-line no-underscore-dangle
      window._env_ || {
        REACT_APP_NO_LOG: 'true',
      };

export default env;
